<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 mt-4">
            <label class="text-sm">Date Debut</label>
            <flat-pickr v-model="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/5 mt-4">
            <label class="text-sm">Date Fin</label>
            <flat-pickr v-model="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col md:w-1/5 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/5 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getReglementClient()">VALIDER</vs-button>
          </div>
          <div class="vx-col md:w-1/5 mt-10">
            <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-file" @click="SearchFacture()">IMPRESSION</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <!-- PDF Generator -->

        <!-- <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="filename"
          :pdf-quality="1"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"

          ref="html2Pdf"
        >
          <section slot="pdf-content">

          </section>
        </vue-html2pdf> -->

        <!-- PDF Generator end -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl">
                  <h1>Etat des réglements</h1>
                </div>
              </div>
              <div class="vx-col w-1/3 mt-base">
                <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <table class="w-full" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;" >Code</th>
                    <th colspan="2" style="border-bottom-width: 0px;border-right-width: 0px;">Nom Client</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">Cde n°</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">Date Cde</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">Part Client</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">Part Ass</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">Total Cde</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">Réglement</th>
                    <th class="text-center" colspan="1" style="border-bottom-width: 0px;">Solde</th>
                  </tr>
                </thead>

                <tbody v-if="reglements_client.length >0">
                  <template v-for="(tr, indextr) in reglements_client">
                    <tr :key="`${indextr}1`">
                      <td colspan="1" style="border-right-style:dotted;border-bottom-width: 0px;"></td>
                      <td class="font-bold" colspan="2" style="border-right-style:dotted;border-bottom-width: 0px;border-left-width:0px;">{{tr.Nom}} {{tr.Prenoms}}</td>
                      <td class="text-center" colspan="1" style="border-right-style:dotted;border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{cde.RefCmmd}}</span>
                        </div>
                      </td>
                      <td class="text-center" colspan="1" style="border-right-style:dotted;border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{cde.DateCommd | moment("calendar", "July 10 2011") }} </span>
                        </div>
                      </td>
                      <td class="text-right" colspan="1" style="border-right-style:dotted;border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{moneyFormatter(cde.PartClient)}} </span>
                        </div>
                      </td>
                      <td class="text-right" colspan="1" style="border-right-style:dotted;border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{moneyFormatter(cde.PartAssu)}} </span>
                        </div>
                      </td>
                      <td class="text-right" colspan="1" style="border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{moneyFormatter((+cde.PartClient + +cde.PartAssu))}} </span>
                        </div>
                      </td>
                      <td class="text-right" colspan="1" style="border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{montantRegler(cde.reglements)}} </span>
                        </div>
                      </td>
                      <td class="text-right" colspan="1" style="border-bottom-width: 0px;border-left-width:0px;">
                        <div :key="indexcde" v-for="(cde, indexcde) in tr.commandes">
                          <span>{{solde(cde.reglements, cde.PartClient, cde.PartAssu)}} </span>
                        </div>
                      </td>
                    </tr>
                    <tr :key="`${indextr}2`">
                      <td class="font-bold" colspan="5" style="border-top-style:dotted;border-right-width:0px">SOUS TOTAL DU CLIENT {{tr.Nom}} {{tr.Prenoms}}</td>
                      <td class="text-right" colspan="1" style="border-top-style:dotted;border-right-width:0px;border-left-width:0px;">{{partClientSousTotal(tr.commandes)}}</td>
                      <td class="text-right" colspan="1" style="border-top-style:dotted;border-right-style:dotted;border-left-width:0px;">{{partAssuSousTotal(tr.commandes)}}</td>
                      <td class="text-right font-bold" colspan="1" style="border-top-style:dotted;border-right-width:0px;border-left-width:0px;">{{totalCmde(tr.commandes)}}</td>
                      <td class="text-right text-primary font-bold" colspan="1" style="border-top-style:dotted;">{{sousTotalRegler(tr.commandes)}}</td>
                      <td class="text-right text-danger font-bold" colspan="1" style="border-top-style:dotted;border-left-width:0px;">{{soldeTotal(tr.commandes)}}</td>
                    </tr>

                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    flatPickr
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      filename: ''
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    reglements_client () {
      return this.$store.state.etat.reglements
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    }
  },
  methods: {
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ' ')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    montantRegler (reglement) {
      const montant = reglement.reduce(function (accumulator, part) {
        return accumulator + +part.Montant
      }, 0)
      return this.moneyFormatter(montant)
    },
    solde (reglement, PartClient, PartAssu) {
      const MontantRegler = reglement.reduce(function (accumulator, part) {
        return accumulator + +part.Montant
      }, 0)

      const finalSolde = ((+PartClient + +PartAssu) - MontantRegler)

      return this.moneyFormatter(finalSolde)
    },
    partClientSousTotal (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.PartClient
      }, 0)
      return this.moneyFormatter(montant)
    },
    partAssuSousTotal (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.PartAssu
      }, 0)
      return this.moneyFormatter(montant)
    },
    totalCmde (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.PartAssu + +commande.PartClient
      }, 0)
      return this.moneyFormatter(montant)
    },
    sousTotalRegler (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.reglements.reduce(function (accumulator, part) {
          return accumulator + +part.Montant
        }, 0)
      }, 0)
      return this.moneyFormatter(montant)
    },
    soldeTotal (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + (+commande.PartClient + +commande.PartAssu) - +commande.reglements.reduce(function (accumulator, part) {
          return accumulator + +part.Montant
        }, 0)
      }, 0)
      return this.moneyFormatter(montant)
    },
    getClientName (id) {
      this.$store.dispatch('client/getClientName', id)
        .then(response => {
          console.log(response.data)
          return response.data.nomClient
        })
        .catch(err => {
          console.error(err)
        })
    },
    getReglementClient () {
      const payload = {
        DateDebut: this.DateDebut,
        DateFin: this.DateFin,
        idOfficine: this.idOfficine
      }

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('etat/getReglementClient', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 4px;
}

.titre_etat_regl{
  border: 1px solid;
  background-color: #CFCFCF;
}
</style>
